<template>
    <div class="cookie-dlg"
        :class="{show:showing}">

        <div class="box">
            <div class="header">

                <h4>{{voMsg('cookie.header')}}</h4>

            </div>
            <div class="body"
                v-show="setup">


                <div class="checkbox checkbox-success">
                    <input type="checkbox"
                        id="cookie_own"
                        onclick="return false;"
                        checked>
                    <label for="cookie_own">{{voMsg('cookies.own')}}</label>
                </div>
                <div class="checkbox checkbox-success">
                    <input type="checkbox"
                        id="cookie_others"
                        v-model="cookiesOk">
                    <label for="cookie_others">{{voMsg('cookies.others')}}</label>
                </div>


            </div>
            <div class="body"
                v-show="!setup">
                <div v-html="introMsg"></div>

                <voffice-doc-box doc-id="privacy">
                    <a class="privacy-link"
                        href="#">{{voMsg('cookies.details')}}</a>
                </voffice-doc-box>
            </div>


            <div class="footer"
                v-show="setup">

                <button type="button"
                    @click="onSaveBtnClick"
                    class="btn btn-primary">{{voMsg('cookies.saveselection')}}</button>

            </div>
            <div class="footer"
                v-show="!setup">

                <button type="button"
                    @click="onSetupBtnClick"
                    class="btn btn-default">{{voMsg('cookies.setup')}}</button>
                <button type="button"
                    @click="onOkBtnClick"
                    class="btn btn-default">{{voMsg('cookies.ok')}}</button>
            </div>
        </div>
    </div>


</template>

<script>
    import V from 'voUtils/V.js'
    import {
    	saveSelection, hasCookieChoice, isCookiesOk
    }
    from 'voUtils/TagEx.js'

    export default {
    	voVueComponent: 'voffice-cookie-box',
    	props: {},
    	data: function() {
    		return {
    			showing: !hasCookieChoice(),
    			setup: false,
    			cookiesOk: isCookiesOk()
    		}
    	},
    	computed: {
    		introMsg: function() {
    			return this.voMsg('cookies.intro').split('\n').join('<br>')
    		}
    	},
    	methods: {
    		onSetupBtnClick: function() {
    			this.setup = true;
    		},
    		onSaveBtnClick: function() {
    			saveSelection(this.cookiesOk);
    			this.showing = false;

    		},
    		onOkBtnClick: function() {
    			saveSelection(true);
    			this.showing = false;
    		}

    	}

    };
</script>

<style scoped>
    .cookie-dlg {
    	padding: 20px;
    	display: none;
    	z-index: 990;
    	background: rgba(0, 0, 0, 0.5);

    	position: fixed !important;
    	left: 0px !important;
    	right: 0px !important;
    	bottom: 0px !important;
    	top: 0px !important;
    	margin: 0px !important;

    	.privacy-link {
    		color: inherit;
    		text-decoration: underline;
    	}

    }

    .box {
    	position: relative;
    	width: 600px;

    	background: #ffffff;
    	border: solid 1px #cccccc;
    	border-radius: 8px;
    	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

    	left: auto;
    	margin-right: auto;
    	margin-left: auto;
    }

    .body {
    	padding: 22px 15px;
    	overflow: auto;
    	position: relative;
    	-webkit-overflow-scrolling: touch;

    }


    .footer {
    	border-top: 1px solid rgb(235, 235, 235);
    	padding: 12px 15px;
    	text-align: right;
    }

    .header {
    	border-bottom: 1px solid rgb(235, 235, 235);
    	padding: 6px 15px;
    	text-align: center;
    }

    .cookie-dlg.show {
    	display: block;
    }





    @media (max-width:767px) {

    	.cookie-dlg {
    		padding: 0;
    		top: auto !important;

    	}



    	.box {
    		width: 100%;
    		height: 100%;
    		border-radius: 0px;
    		border: none;

    	}



    }
</style>