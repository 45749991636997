export default {
	data: {
		selectedImageIndex: undefined,
		showAnmelden: true
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	updated: function () {
		console.log('dummy updated..');
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	mounted: function () {
		console.log('dummy updated..');
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}
	},
	methods: {
		allowGoogleMaps: function () {
			console.log('allowing google maps...');
			localStorage.setItem('allowGoogleMaps', true);
			location.reload();
		},
		preventGoogleMaps: function () {
			localStorage.setItem('allowGoogleMaps', false);
			location.reload();
		},
		canShowGoogleMaps: function () {
			var GoogleMaps = localStorage.getItem("allowGoogleMaps");
			if (GoogleMaps === 'true') {
				return true;
			}
			return false;
		}
	}

};